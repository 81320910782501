<template>
    <b-row>
      <b-col cols="12">
        <b-form-group>
          <v-select
            :disabled="models==null"
            v-if="models != null"
            v-model="selected"
            chips
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="models"
            class="select-size-lg"
            filled
            multiple
          />
        </b-form-group>
      </b-col>
    </b-row>
  </template>
    
  <script>
  import { BRow, BCol, BFormGroup } from "bootstrap-vue";
  import vSelect from "vue-select";
  import Ripple from "vue-ripple-directive";
  // import { getCall } from "../Service";
  
  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      vSelect,
    },
    directives: {
      Ripple,
    },
  
    props: {
      brandId: "",
      models:[],
      selected: '',
    },
    methods: {
     
    },
    watch: {
      selected: function (newVal, coldVal) {
          console.log('object',newVal,coldVal);
        this.$emit("selectionChanged", newVal);
      },
    },
  
    data() {
      return {
        items: [],
      };
    },
  };
  </script>
    